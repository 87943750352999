<!--商品维护-->
<template>
  <div style="padding: 20px;">
    <div>
      <div style="display: flex;align-items: center;justify-content: space-between;">
        <span style="font-size: 24px;font-weight: 500;color: #707070;">商品维护</span>
        <el-button style="background: #E4E5E9!important;border-color: #E4E5E9!important;color: #3F4157!important;" @click="returnPage()">返回</el-button>
      </div>

      <div style="width: 80%;margin: auto;margin-top: 30px;">
        <el-form ref="form" :model="form" label-width="100px">
          <el-card shadow="never">
            <div style='width: 50%;margin: auto;'>
              <div style="text-align: center;margin-top: 20px;">
                <span style="font-size: 30px;font-weight: 500;color: #000000;">提现申请</span>
              </div>
              <div style="margin-top: 30px;">
                <el-form-item label="客户代码">
                  <el-input
                      clearable
                      v-model="form.facilitator_name"
                      placeholder="请输入客户代码"/>
                </el-form-item>
                <el-form-item label="姓名">
                  <el-input
                      clearable
                      v-model="form.facilitator_name"
                      placeholder="请输入姓名"/>
                </el-form-item>
                <el-form-item label="手机号">
                  <el-input
                      clearable
                      v-model="form.facilitator_name"
                      placeholder="请输入手机号"/>
                </el-form-item>
                <el-form-item label="密码">
                  <el-input
                      clearable
                      v-model="form.facilitator_name"
                      placeholder="请输入密码"/>
                </el-form-item>
                <el-form-item label="账号">
                  <el-input
                      clearable
                      v-model="form.facilitator_name"
                      placeholder="请输入账号"/>
                </el-form-item>
                <el-form-item label="状态">
                  <el-radio v-model="form.radio" label="1">启用</el-radio>
                  <el-radio v-model="form.radio" label="2">禁用</el-radio>
                </el-form-item>
              </div>
            </div>
          </el-card>
        </el-form>

      </div>
    </div>

    <div style="margin-top: 50px;text-align: center;">
      <el-button type="warning" style="width: 200px !important; margin-left: 20px;">提交</el-button>
      <el-button style="width: 200px !important;">取消</el-button>
    </div>

  </div>
</template>

<script>
export default {
  data(){
    return{
      form:{},

    }
  },

  methods:{
    //点击返回按钮
    returnPage(){
      this.$router.go(-1);
    },

  },

}
</script>

<style scoped lang="scss">

</style>
